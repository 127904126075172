export const loggin = () => {
  return {
    type: "LOGIN",
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export const setUsers = (dt) => {
  return {
    type: "SET_USERS",
    payload: dt,
  };
};
