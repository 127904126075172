import React, { Component, useState } from "react";
import { Pagination } from "@mui/material";
const PaginationComponent = ({
  postsPerPage,
  totalPosts,
  currentPage,
  paginate,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Pagination
        count={pageNumbers.length}
        onChange={(e, p) => paginate(p)}
        style={{ paddingTop: "10px" }}
        color={"primary"}
      />
    </>
  );
};

export default PaginationComponent;
