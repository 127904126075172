import React, { useState } from "react";
import { CircularProgress, Typography, Button } from "@mui/material";
import { CheckCircle, ErrorOutline } from "@mui/icons-material";
import "./Modal.css";
import styles from "../sidenav.module.css";
const Modal = ({
  setOpenModal,
  handleAction,
  currentId,
  loading,
  error,
  feedBack,
  setFeedback,
  setLoading,
  setError,
}) => {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => setOpenModal(false)}>X</button>
        </div>

        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <p>Loading....</p> &nbsp;
            <CircularProgress color={"primary"} size={50} />
          </div>
        ) : feedBack ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CheckCircle color="success" style={{ fontSize: 50 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                {feedBack}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: 20,
                }}
              >
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    setOpenModal(false);
                    setFeedback(null);
                    setError(null);
                    setLoading(false);
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </>
        ) : error ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ErrorOutline color="error" style={{ fontSize: 50 }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                {error}
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  margin: 20,
                }}
              >
                <Button
                  variant={"outlined"}
                  onClick={() => setOpenModal(false)}
                >
                  Ok
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3>Are you sure you want to Continue?</h3>
            </div>
            <div className="body">
              {/* <p>Do you want to proceed with the current action?</p> */}
            </div>
            <div className="footer">
              <button id="cancelBtn" onClick={() => setOpenModal(false)}>
                Cancel
              </button>
              <button onClick={handleAction}>Continue</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
