import React, { useState } from "react";
import "./formInput.css";
const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const {
    label,
    onchange,
    handleData,
    id,
    type,
    errorMessage,
    value,
    data,
    hasEventData,
    setAmountApplied,
    ...inputProps
  } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return type === "checkbox" ? (
    <div className="formInputCheckbox">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignContent: "center",
          paddingLeft: 10,
        }}
      >
        <div>
          <label>{label}</label>
        </div>
        <div>
          <input
            id={id}
            type={type}
            value={value}
            {...inputProps}
            onChange={onchange}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="formInput">
      <label>{label}:</label>
      {type === "select" ? (
        <select value={value} {...inputProps} onChange={onchange}>
          {data.map((item) => {
            return <option key={item}>{item}</option>;
          })}
        </select>
      ) : type === "date" ? (
        <input type={type} value={value} {...inputProps} onChange={onchange} />
      ) : type === "datalist" ? (
        <>
          {hasEventData ? (
            <input
              type={type}
              value={value}
              list={"member"}
              {...inputProps}
              onChange={(e) => {
                onchange(e);
                handleData(e);
              }}
            />
          ) : (
            <input
              type={type}
              value={value}
              list={"member"}
              {...inputProps}
              onChange={onchange}
            />
          )}

          <datalist id="member">
            {data.map((item) => {
              return <option key={item}>{item}</option>;
            })}
          </datalist>
        </>
      ) : (
        <input
          type={type}
          value={value}
          {...inputProps}
          onChange={onchange}
          onBlur={handleFocus}
          onFocus={() => {
            inputProps.name === "confirmPassword" && setFocused(true);
          }}
          focused={focused.toString()}
        />
      )}
      <span className="errorSpan">{errorMessage}</span>
    </div>
  );
};

export default FormInput;
