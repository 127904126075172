import React, { useEffect, useState } from "react";
import { urlPath } from "../../lib/urlPath";
import styles from "../../Components/sidenav.module.css";
import { Typography } from "@mui/material";

const ViewLoanGuarantors = ({ loanId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const url = urlPath.mainPath;
  const guarantorPath = `${url}guarantor/loan_guarantors/${loanId}`;

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setData(null);

    const getGuarantors = async () => {
      try {
        const response = await fetch(guarantorPath, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();

        setIsLoading(false);
        setData(result.data);
        setError(null);
      } catch (err) {
        setIsLoading(false);
        setError(err.toString());
        setData(null);
      }
    };
    getGuarantors();
  }, loanId);

  return (
    <>
      <br />
      <br />
      {data && (
        <fieldset style={{ borderRadius: 8 }}>
          <legend style={{ padding: 2 }}>
            <Typography
              variant="subtitle1"
              style={{
                color: "rgb(10, 100, 100)",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Guarantors
            </Typography>
          </legend>
          <table id={styles.general_table}>
            <thead>
              <th>#</th>
              <th>Registration #</th>
              <th>Name</th>
              <th>Contacts</th>
              <th>Amount to Guarantor</th>
            </thead>
            {data.map((guarantor, index) => {
              return (
                <tr style={{ fontSize: 12 }}>
                  <td>{index + 1}</td>
                  <td>{guarantor.registration_number}</td>
                  <td>
                    {guarantor.first_name} {guarantor.last_name}{" "}
                    {guarantor.middle_name}
                  </td>
                  <td>
                    {guarantor.mobile_number} <br />
                    {guarantor.email}
                  </td>
                  <td>{guarantor.amount_to_gurantor}</td>
                </tr>
              );
            })}
          </table>
        </fieldset>
      )}
    </>
  );
};

export default ViewLoanGuarantors;
