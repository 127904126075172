import React, { Component, useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import styles from "./sidenav.module.css";
import { ArrowForward, ArrowRight } from "@mui/icons-material";

const SideNavDropdown = ({ pages, id, open, dropdownRef, currentTab }) => {
  const [visibility, setVisible] = useState(false);
  useEffect(() => {
    const ul_id = `dropdown${id}`;

    ul_id === dropdownRef.current.id ? setVisible(true) : setVisible(false);
    // ul_id === dropdownRef.current.id
    //   ? (dropdownRef.current.style.display = "block")
    //   : (dropdownRef.current.style.display = "none");
  }, [dropdownRef.current.id]);

  return (
    <>
      <ul
        // style={{ margin: "0px", display: "none" }}

        style={{
          margin: "0px",
          transition: "all 0.5s ease",
          paddingLeft: "20px",
          backgroundColor: "rgb(198, 235, 213)",

          display:
            currentTab !== null
              ? visibility === true
                ? "block"
                : "none"
              : "none",
        }}
        ref={dropdownRef}
        id={`dropdown${id}`}
      >
        {pages.map((page, index) => {
          return (
            <li
              style={{ transition: "all 0.5s ease", padding: "0px" }}
              key={
                //   index === 0 || id === 0
                //     ? Math.floor(Math.random() * 50)
                //     : index * id
                index
              }
              className={
                open
                  ? `${styles.SideNavDropdown}`
                  : `${styles.SideNavDropdownClosed}`
              }
            >
              {open ? <ArrowRight style={{ transition: "500ms" }} /> : null}
              <NavLink
                className={styles.dropDownListItem_text}
                to={page.link}
                style={{ transition: "all 0.5s ease", padding: "10px" }}
              >
                {page.text}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SideNavDropdown;
