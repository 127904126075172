import React, { Component, useEffect, useState } from "react";
import styles from "../../Components/sidenav.module.css";
import FormInput from "../../Components/FormInput";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { urlPath } from "../../lib/urlPath";
import axios from "axios";
import LoansDisplayTable from "./LoansDisplayTable";

const LoansSummary = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [memberLoans, setMemberLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    //alert(newTabIndex);
  };
  const url = `${urlPath.mainPath}loans`;

  useEffect(() => {
    // setLoading(true);
    // setMemberLoans([]);
    // setError(null);
    // const fetchLoans = async () => {
    //   try {
    //     const result = await axios.get(url);
    //     setMemberLoans(result.data.data);
    //     setLoading(false);
    //     setError(null);
    //     // alert(memberLoans[0].id);
    //   } catch (error) {
    //     setError(error);
    //   }
    // };

    // fetchLoans();
  }, []);

  return (
    <>
      <div className={styles.topSubContent}>
        <h3>Loans</h3>
      </div>
      <div className={`${styles.mainContent}`}>
        <Box>
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Active Loans" />
              <Tab label="Pending" />
              <Tab label="Defaulted" />
            </Tabs>
          </Box>
          <Box sx={{ padding: 2 }}>
            {tabIndex === 0 && (
              <Box>
                <Typography>
                  <LoansDisplayTable statusType={"ACTIVE"} />
                </Typography>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box>
                <Typography>
                  <LoansDisplayTable statusType={"PENDING"} />
                </Typography>
              </Box>
            )}
            {tabIndex === 2 && (
              <Box>
                <Typography>
                  <LoansDisplayTable statusType={"DEFAULT"} />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default LoansSummary;
