import styles from "../Components/sidenav.module.css";
import {
  Home,
  TravelExplore,
  Settings,
  BarChart,
  Explore,
  Dashboard,
  Paid,
  Groups,
  Group,
  MoneyOff,
  Toll,
  AccountBalanceOutlined,
} from "@mui/icons-material";

export const navData = [
  {
    id: 20,
    icon: <Dashboard className={styles.icon} />,
    text: "Dashboard",
    link: "/",
  },
  {
    id: 21,
    icon: <Groups className={styles.icon} />,
    text: "Clients",
    // link: "client_registration",
    pages: [
      {
        text: "View Clients",
        link: "view_clients",
        id: 211,
      },
      {
        text: "Client Registration",
        link: "client_registration",
        id: 212,
      },
      {
        text: "Next of Kin Registration",
        link: "next_of_kin_registration",
        id: 213,
      },
      {
        text: "Edit Bio Data",
        link: "edit_bio_data",
        id: 214,
      },
      {
        text: "Edit Next of Kin",
        link: "edit_next_of_kin",
        id: 215,
      },
      {
        text: "Add Member Savings ",
        link: "add_new_member_savings",
        id: 2160,
      },
      {
        text: "View Member Savings ",
        link: "view_member_savings",
        id: 2160,
      },
      {
        text: "Add Member Savings Payments",
        link: "add_member_savings",
        id: 216,
      },
      {
        text: " Member Monthly Contribution List",
        link: "savings_payments",
        id: 217,
      },
    ],
  },
  {
    id: 22,
    icon: <Toll className={styles.icon} />,
    text: "Loans",
    // link: "apply_loan",
    pages: [
      {
        text: "Apply Loan",
        link: "apply_loan",
        id: 221,
      },
      {
        text: "Guarantee Loans",
        link: "guarantee_loans",
        id: 226,
      },
      // {
      //   text: "Add Collateral",
      //   link: "add_collateral",
      //   id: 222,
      // },
      {
        text: "Approve Loans",
        link: "approve_loans",
        id: 223,
      },
      // {
      //   text: "Cancel Application",
      //   link: "cancel_application",
      //   id: 224,
      // },
      {
        text: "Disburse Loans",
        link: "disburse_loans",
        id: 225,
      },
      // {
      //   text: "Loan Calculator",
      //   link: "loan_calculator",
      //   id: 227,
      // },
      {
        text: "Make Loan Payments",
        link: "process_loans",
        id: 228,
      },
    ],
  },
  {
    id: 235,
    icon: <MoneyOff className={styles.icon} />,
    text: "Expenses",
    // link: "add_loan_products",
    pages: [
      {
        text: "Suppliers",
        link: "supplier_list",
        id: 2411,
      },
      {
        text: "Add Supplier",
        link: "add_supplier",
        id: 24111,
      },
      {
        text: "Vendor Bills",
        link: "bills_list",
        id: 2142,
      },
      {
        text: "Add Bill",
        link: "add_bill",
        id: 242,
      },
      {
        text: "Pay Bills",
        link: "pay_bills",
        id: 24343,
      },
      {
        text: "Pay Bills Report",
        link: "pay_bills_report",
        id: 24343,
      },
      // {
      //   text: "Active Loans",
      //   link: "active_loans",
      //   id: 244,
      // },
      /* {
        text: "Aging Report",
        link: "aging_report",
        id: 245,
      },*/
      // {
      //   text: "Defaulted Loans",
      //   link: "defaulted_loans",
      //   id: 246,
      // },

      /*{
        text: "Due Loans",
        link: "due_loans",
        id: 246,
      },
      {
        text: "General Statement",
        link: "general_statement",
        id: 247,
      },
      {
        text: "Loan Balances",
        link: "loan_balances",
        id: 246,
      },
      {
        text: "Loan Statement",
        link: "loan_statement",
        id: 247,
      },
      {
        text: "Pending Loans",
        link: "pending_loans",
        id: 246,
      },
      {
        text: "Rejeced Loans",
        link: "rejected_loans",
        id: 247,
      },
      {
        text: "Undisbursed Loans",
        link: "undisbursed_loans",
        id: 246,
      },
      {
        text: "Waived Loans",
        link: "waived_loans",
        id: 247,
      },
      {
        text: "Written Off Loans",
        link: "written_off_loans",
        id: 246,
      },*/
    ],
  },
  // {
  //   id: 23,
  //   icon: <BarChart className={styles.icon} />,
  //   text: "Reports",
  //   pages: [
  //     { text: "Payment Reports", link: "payment_reports", id: 241 },
  //     { text: "Deductions Schedule", link: "account_statement", id: 242 },
  //     { text: "Active Loans", link: "active_loans", id: 244 },
  //     { text: "Defaulted Loans", link: "defaulted_loans", id: 246 },
  //     { text: "Disbursed Loans", link: "disbursed_loans", id: 247 },
  //   ],
  // },
  {
    id: 2355,
    icon: <AccountBalanceOutlined className={styles.icon} />,
    text: "Finance",
    pages: [
      { text: "GL Account Listing", link: "gl_account", id: 2412 },
      { text: "Add Gl Account", link: "gl_account_add", id: 2472 },
      {
        text: "Income / Expenditure Report",
        link: "income_expenditure",
        id: 2473,
      },
      { text: "Trial Balance ", link: "trial_balance", id: 2418 },
      { text: "Balance Sheet", link: "balance_sheet", id: 21429 },
      // { text: "Gl Journals", link: "gl_journals", id: 24211 },
      // { text: "Office Transfers", link: "office_transfers", id: 2457 },
    ],
  },
  {
    id: 23,
    icon: <BarChart className={styles.icon} />,
    text: "Reports",
    pages: [
      { text: "Member Payment", link: "payment_reports", id: 241 },
      { text: "Loan Payments", link: "loan_payments", id: 247 },
      { text: "Disbursed Loans", link: "disbursed_loans", id: 2478 },
      { text: "Savings ", link: "savings_report", id: 24111 },
      { text: "Loans", link: "loans_report", id: 2142 },
      { text: "Deductions Schedule", link: "account_statement", id: 242 },
      { text: "Disbursed Loans", link: "disbursed_loans", id: 247 },
      { text: "Audit Report", link: "audit_report", id: 2428 },
    ],
  },
  {
    id: 24,
    icon: <Settings className={styles.icon} />,
    text: "Settings",
    // link: "add_loan_products",
    pages: [
      // {
      //   text: "Add Loan Products",
      //   link: "add_loan_products",
      //   id: 231,
      // },
      // {
      //   text: "Edit Loan Product",
      //   link: "editproducts",
      //   id: 232,
      // },
      // {
      //   text: "Business Details",
      //   link: "business_details",
      //   id: 234,
      // },
      // {
      //   text: "System Configurations",
      //   link: "system_configurations",
      //   id: 235,
      // },
      // {
      //   text: "User Activities",
      //   link: "user_activities",
      //   id: 236,
      // },
      {
        text: "User Management",
        link: "user_management",
        id: 237,
      },
    ],
  },
];
