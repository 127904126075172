import React, { Component, useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Print } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";

import styles from "../../Components/sidenav.module.css";
import { urlPath } from "../../lib/urlPath";
import ViewLoanDetails from "./ViewLoanDetails";
import ViewLoanGuarantors from "./ViewLoanGuarantors";

const ViewLoanSchedule = ({
  openViewSchedule,
  setOpenViewSchedule,
  loanId,
  memberId,
  memberName,
  registrationNo,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [ttPayable, setTTPayable] = useState(0);
  const [ttPaid, setTTPaid] = useState(0);
  const [loanBalance, setLoanBalance] = useState(0);
  const [principalInstallmentTT, setPrincipalInstallmentTT] = useState(0);
  const [interestInstallmentTT, setInterestInstallmentTT] = useState(0);

  const [openDialog, setOpenDialog] = useState(true);
  const url = urlPath.mainPath;
  const scheduleRoute = `${url}loan_schedule/${loanId}`;

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
    setOpenViewSchedule(false);
  };
  useEffect(() => {
    let controller = new AbortController();
    // getSavings(controller);

    fetch(scheduleRoute, {
      signal: controller.signal,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setLoading(false);
        setError(null);
        setData(result.data);

        let tt_payable = result.data.reduce((total, installment) => {
          return total + installment.monthly_installment;
        }, 0);
        setTTPayable(Intl.NumberFormat().format(tt_payable));

        let tt_paid = result.data.reduce((total, installment) => {
          return total + installment.total_amount_paid;
        }, 0);

        setTTPaid(Intl.NumberFormat().format(tt_paid));
        setLoanBalance(tt_payable - tt_paid);
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          setLoading(false);
          setError(err.toString());
          setData(null);
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openDialog}
        onClose={handleOpenDialog}
        // sx={{
        //   "& .MuiDialog-container": {
        //     justifyContent: "center",
        //     alignItems: "flex-start",
        //   },
        // }}
        PaperProps={{ sx: { height: "100%" } }}
      >
        {/* <DialogTitle>Loan schedule</DialogTitle> */}
        <DialogContent style={{ margin: "10px", backgroundColor: "white" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="submit"
              onClick={handlePrint}
              variant="outlined"
              startIcon={<Print color="success" />}
            >
              Print
            </Button>
          </div>
          <div
            style={{ width: "100%", padding: "10px", backgroundColor: "white" }}
            ref={componentRef}
          >
            <div className={styles.mainComponent}>
              <div>
                {error && (
                  <div className="error_box">
                    <Typography variant="subtitle2">{error}</Typography>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "centr",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <CircularProgress color={"primary"} size={25} />
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <div>
                  {" "}
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Teau Staff Co-operative Society Ltd.
                  </Typography>
                </div>

                <div>
                  {" "}
                  <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                    Member Loan Report
                  </Typography>
                  <br></br>
                </div>
              </div>
              <Typography
                variant="subtitle2"
                style={{ fontSize: 12, fontWeight: 600 }}
              >
                <span style={{ color: "rgb(10, 100, 100)" }}>
                  Registration Number
                </span>{" "}
                : &nbsp;{registrationNo}
                <br />
                <span style={{ color: "rgb(10, 100, 100)" }}> Name</span>:
                &nbsp; {memberName}
              </Typography>
              <br />
              <table id={styles.general_table}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Starting Principal Balance</th>
                    <th>Principal Installment</th>
                    <th>Interest Installment</th>
                    <th>Monthly Installment</th>
                    {/* <th>Ending Principal Balance</th> */}
                    <th>Total Amount Paid</th>
                    {/* <th>Balance Amount</th> */}
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((schedule, index) => {
                      return (
                        <tr key={schedule.id} style={{ fontSize: 11 }}>
                          <td>{schedule.installment_number}</td>
                          <td>{schedule.installment_date}</td>
                          <td>{schedule.starting_principal_balance}</td>
                          <td>{schedule.principal_installment}</td>
                          <td>{schedule.interest_installment}</td>
                          <td>{schedule.monthly_installment}</td>
                          {/* <td>{schedule.ending_principal_balance}</td> */}
                          <td>{schedule.total_amount_paid}</td>
                          {/* <td>{schedule.balance_amount}</td> */}
                          <td>
                            {Intl.NumberFormat().format(
                              parseFloat(schedule.monthly_installment) -
                                parseFloat(schedule.total_amount_paid)
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  <tr style={{ backgroundColor: "rgb(222, 247, 229)" }}>
                    <td colspan={5}></td>
                    <td>{ttPayable}</td>
                    {/* <td></td> */}
                    <td>{ttPaid}</td>
                    <td>{loanBalance.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ViewLoanDetails loanId={loanId} />

            <ViewLoanGuarantors loanId={loanId} />
          </div>
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={handleOpenDialog}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default ViewLoanSchedule;
