import React, { Component, useState, useEffect } from "react";
import styles from "../../Components/sidenav.module.css";
import { urlPath } from "../../lib/urlPath";
import { CircularProgress, Typography } from "@mui/material";

const ViewLoanDetails = ({ loanId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const url = urlPath.mainPath;
  const loanPath = `${url}loans/${loanId}`;

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setData(null);
    const getDetails = async () => {
      try {
        const response = await fetch(loanPath, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        setIsLoading(false);
        setData(result.data);
        setError(null);
      } catch (err) {
        setIsLoading(false);
        setError(err.toString());
        setData(null);
      }
    };
    getDetails();
  }, loanId);

  return (
    <>
      <br />
      <br />
      <br />
      {data && (
        <fieldset style={{ borderRadius: 8 }}>
          <legend style={{ padding: 2 }}>
            <Typography
              variant="subtitle1"
              style={{
                color: "rgb(10, 100, 100)",
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Loan Details
            </Typography>
          </legend>
          <div
            className={styles.displayContentContainer}
            style={{ fontSize: 12 }}
          >
            <div className={styles.displayContentChild}>
              <div>
                <b>Loan #</b>
                {data.id}
              </div>
              <div>
                <b>Application Date:&nbsp;</b>
                {data.application_date}
              </div>
              <div>
                <b>Loan Status:&nbsp;</b>
                {data.loan_status}
              </div>
            </div>
            <div className={styles.displayContentChild}>
              <div>
                <b>Loan Amount:&nbsp;</b>
                {data.loan_amount}
              </div>
              <div>
                <b>Tenure Period:&nbsp;</b>
                {data.tenure_period}
              </div>
              <div>
                <b>Total Disbursed:&nbsp;</b>
                {data.total_disbursed}
              </div>
            </div>
            <div className={styles.displayContentChild}>
              <div>
                <b>Interest Amount:&nbsp;</b>
                {data.interest_amount}
              </div>
              <div>
                <b>Total Payable:&nbsp;</b>
                {data.total_payable}
              </div>
              <div>
                <b>Monthly Installment:&nbsp;</b>
                {data.monthly_installment}
              </div>
            </div>
            {/* <div className={styles.displayContentChild}>
              <div>
                <b>Total Amount Paid: &nbsp;</b>
                {data.interest_amount}
              </div>
              <div>
                <b>Loan Balance:&nbsp;</b>
                {data.total_payable}
              </div>
              
            </div> */}
          </div>
        </fieldset>
      )}
    </>
  );
};

export default ViewLoanDetails;
