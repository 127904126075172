import React, { Component, useEffect, useState } from "react";
import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import styles from "../../Components/sidenav.module.css";
import FormInput from "../../Components/FormInput";
import {
  Delete,
  Preview,
  Verified,
  AccountBalance,
  PaymentOutlined,
  CheckCircle,
  ErrorOutline,
} from "@mui/icons-material";
import PaginationComponent from "../../Components/PaginationComponent";
import Modal from "../../Components/modal/Modal";
import { urlPath } from "../../lib/urlPath";
import { mergeBreakpointsInOrder } from "@mui/system";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";

import ViewLoanSchedule from "./ViewLoanSchedule";

// import ClientList from "../../Components/client/ClientList";

const LoansDisplayTable = ({ statusType }) => {
  const searchKeys = ["first_name", "last_name", "middle_name"];
  const [members, setMembers] = useState([]);
  const [query, setQuery] = useState("");
  const [currentMemberId, setCurrentMemberId] = useState(null);
  const [currentRegistrationNo, setCurrentRegisrationNo] = useState("");
  const [currentMemberName, setCurrrentMemberName] = useState("");
  const [currentLoanId, setCurrentLoanId] = useState(null);
  const [currentLoan, setCurrentLoan] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openDisbursementDialog, setOpenDisbursementDialog] = useState(false);
  const [fetchMessage, setFetchMessage] = useState(null);
  const [loansUpdate, setLoansUpdate] = useState(0);

  //data fetch state transition
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedBack, setFeedback] = useState(null);

  const [loadingMembers, setLoadingMembers] = useState(false);
  const [errorMembers, setErrorMembers] = useState(null);
  const [feedBackMembers, setFeedbackMembers] = useState(null);

  //loan schedule component
  const [openViewSchedule, setOpenViewSchedule] = useState(false);

  //disbursement fields
  const [disbursementValues, setDisbursementValues] = useState({
    date: "",
    method: "",
    referenceNumber: "",
    amount: "",
  });
  //loan payment fields
  const [paymentValues, setPaymentValues] = useState({
    date: "",
    method: "",
    referenceNumber: "",
    amount: "",
    description: "",
  });

  //set input fields for disbursement
  const inputs1 = [
    {
      id: 1,
      name: "date",
      type: "date",
      required: true,
      label: "Disbursement Date",
    },
    {
      id: 2,
      name: "method",
      type: "select",

      label: "Payment Method",
      required: true,
      errorMessage: "Please Select Payment Method",
      data: ["", "CHEQUE", "MPESA", "Bank Transfer"],
    },
    {
      id: 3,
      name: "referenceNumber",
      type: "text",
      placeholder: "Enter Reference Number",
      label: "Reference Number:",
      required: true,
      errorMessage: "Please enter Transaction Reference Number",
    },
    {
      id: 4,
      name: "amount",
      type: "number",
      placeholder: "Enter Amount Disbursed",
      label: "Amount",
      required: true,
      errorMessage: "Please enter Amount Disbursed",
    },
  ];
  const inputs2 = [
    {
      id: 1,
      name: "date",
      type: "date",
      required: true,
      label: "Payment Date",
    },
    {
      id: 2,
      name: "method",
      type: "select",

      label: "Payment Method",
      required: true,
      errorMessage: "Please Select Payment Method",
      data: ["", "CHEQUE", "MPESA", "Bank Transfer"],
    },
    {
      id: 3,
      name: "referenceNumber",
      type: "text",
      placeholder: "Enter Reference Number",
      label: "Reference Number",
      required: true,
      errorMessage: "Please enter Transaction Reference Number",
    },
    {
      id: 4,
      name: "amount",
      type: "number",
      placeholder: "Enter Amount Disbursed",
      label: "Amount",
      required: true,
      errorMessage: "Please enter Amount Disbursed",
    },
    {
      id: 4,
      name: "description",
      type: "text",
      placeholder: "Enter description",
      label: "Memo",
    },
  ];

  //pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  function handleToggle() {
    setOpen(!open);
  }

  //set url
  const url = urlPath.mainPath;

  useEffect(() => {
    setLoadingMembers(true);
    setErrorMembers(null);
    setFeedbackMembers(null);
    const fetchMembers = async () => {
      try {
        const res = await axios.get(`${url}loans/joined`);
        // console.log(res.data.data);

        let loansArr = [...res.data.data];
        loansArr = loansArr.filter((loan, index) => {
          return loan.loan_status == statusType;
        });
        // alert(statusType);
        setMembers(loansArr);
        setLoadingMembers(false);
        setErrorMembers(null);
        setFeedbackMembers(null);
      } catch (error) {
        setLoadingMembers(false);
        setErrorMembers(error);
        setFeedbackMembers(null);
      }
    };

    fetchMembers();
  }, [loansUpdate]);

  //get current member list
  const indexOfLastItem = currentPage * postsPerPage;
  const indexOfFirstItem = indexOfLastItem - postsPerPage;

  const currentItemList = members.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (number) => {
    setCurrentPage(number);
  };

  const approveLoan = async (loanId) => {
    setOpen(true);
    const result = await axios
      .get(`${url}loans/approve_loan/${loanId}`)
      .then((result) => {
        setOpen(false);
        setFetchMessage(result.data.description);
      });
  };

  const makeLoanPayment = async (e) => {
    e.preventDefault();

    setFeedback(null);
    setLoading(true);
    setError(null);

    // if (
    //   paymentValues.date == "" ||
    //   paymentValues.method == "" ||
    //   paymentValues.date.referenceNumber == "" ||
    //   paymentValues.amount < 1
    // ) {
    //   setFeedback("Please Fill all Fields to Continue");
    //   setLoading(false);
    //   return;
    // }

    const data = {
      loanId: currentLoanId,
      date: paymentValues.date,
      method: paymentValues.method,
      referenceNumber: paymentValues.referenceNumber,
      amount: paymentValues.amount,
      description: paymentValues.description,
    };

    fetch(`${url}loan_payments/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((result) => result.json())
      .then((response) => {
        setPaymentValues({
          date: "",
          method: "",
          referenceNumber: "",
          amount: "",
          description: "",
        });
        setLoading(false);
        setError(false);
        setFeedback(response.description);
        setLoansUpdate((val) => {
          return val + 1;
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Error Submitting Details");
      });
  };

  const disburseLoan = async (e) => {
    e.preventDefault();

    setFeedback(null);
    setLoading(true);
    setError(false);

    // if (
    //   disbursementValues.date == "" ||
    //   disbursementValues.method == "" ||
    //   disbursementValues.date.referenceNumber == "" ||
    //   disbursementValues.amount < 1
    // ) {
    //   setFeedback("Please Fill all Fields to Continue");
    //   setLoading(false);
    //   return;
    // }

    const data = {
      loanId: currentLoanId,
      date: disbursementValues.date,
      method: disbursementValues.method,
      referenceNumber: disbursementValues.referenceNumber,
      amount: disbursementValues.amount,
    };

    fetch(`${url}loans/loanDisbursement/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((result) => result.json())
      .then((response) => {
        setDisbursementValues({
          date: "",
          method: "",
          referenceNumber: "",
          amount: "",
        });
        setLoading(false);
        setError(false);
        setFeedback(response.description);
        setLoansUpdate((val) => {
          return val + 1;
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Error Submitting Details");
      });
  };

  const onChange = (e) => {
    setDisbursementValues({
      ...disbursementValues,
      [e.target.name]: e.target.value,
    });
  };
  const onChangePayment = (e) => {
    setPaymentValues({
      ...paymentValues,
      [e.target.name]: e.target.value,
    });
  };

  //handle member loan delete
  const handleLoanDelete = async () => {
    setLoading(true);
    try {
      const deleteLoan = await axios.delete(`${url}loans/${currentLoanId}`);

      setLoading(false);
      setError(false);
      setFeedback("Loan Deleted Successfully!");
      let memberList = [...members];

      memberList.filter((loan, index) => {
        return loan.loan_id != currentLoanId;
      });

      setMembers(memberList);
      setLoansUpdate((state) => {
        return state + 1;
      });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <>
      {openModal && (
        <Modal
          setOpenModal={setOpenModal}
          handleAction={handleLoanDelete}
          currentId={currentMemberId}
          loading={loading}
          error={error}
          feedBack={feedBack}
          setFeedback={setFeedback}
          setLoading={setLoading}
          setError={setError}
        />
      )}

      <div className={styles.mainContent}>
        <Dialog
          open={openDisbursementDialog}
          onClose={() => setOpenDisbursementDialog(false)}
          aria-labelledby="dialog-title"
        >
          <DialogTitle id="dialog-title">
            {statusType == "ACTIVE" ? (
              <p>Add Loan Payment</p>
            ) : (
              <p>Add Loan Disbursement</p>
            )}
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <p>Loading....</p> &nbsp;
                <CircularProgress color={"primary"} size={50} />
              </div>
            ) : feedBack ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <CheckCircle color="success" style={{ fontSize: 50 }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                    {feedBack}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: 20,
                    }}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        setOpenDisbursementDialog(false);
                        setFeedback(null);
                        setError(null);
                        setLoading(false);
                      }}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </>
            ) : error ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ErrorOutline color="error" style={{ fontSize: 50 }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                    {error}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: 20,
                    }}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => setOpenDisbursementDialog(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <form
                onSubmit={
                  statusType == "ACTIVE" ? makeLoanPayment : disburseLoan
                }
              >
                <div className={styles.formSubContent}>
                  {statusType == "ACTIVE"
                    ? inputs2.map((input) => {
                        return (
                          <FormInput
                            key={input.id}
                            {...input}
                            onchange={onChangePayment}
                            value={paymentValues[input.name]}
                          />
                        );
                      })
                    : inputs1.map((input) => {
                        return (
                          <FormInput
                            key={input.id}
                            {...input}
                            onchange={onChange}
                            value={disbursementValues[input.name]}
                          />
                        );
                      })}
                </div>
                <DialogActions>
                  <Button onClick={() => setOpenDisbursementDialog(false)}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={loading}>
                    Submit
                  </Button>
                </DialogActions>
              </form>
            )}
          </DialogContent>
        </Dialog>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={styles.searchContent}>
          <input
            placeholder="Search Members.."
            type="text"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <br />
        <div
          style={{
            color: "rgb(5, 117, 33)",
            padding: "10px",
            borderBottom: "1px solid #ccc",
            width: "100%",
            display: "block",
          }}
        >
          {fetchMessage && <p>{fetchMessage}</p>}
        </div>
        <div>
          {errorMembers && (
            <div className="error_box">
              <Typography variant="subtitle2">{errorMembers}</Typography>
            </div>
          )}
        </div>
        <div>
          {feedBackMembers && (
            <div className="success_box">
              <Typography variant="subtitle2">{feedBackMembers}</Typography>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "centr",
            alignItems: "center",
          }}
        >
          {loadingMembers ? (
            <CircularProgress color={"primary"} size={25} />
          ) : null}
        </div>
        <table id={styles.general_table}>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Middle Name</th>
              <th>Contacts</th>
              <th>Loan</th>
              <th>Loan Amount</th>
              <th>Interest Amount</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentItemList
              .filter((item, index) => {
                return (
                  item.first_name.toLowerCase().includes(query.toLowerCase()) ||
                  item.last_name.toLowerCase().includes(query.toLowerCase()) ||
                  item.middle_name.toLowerCase().includes(query.toLowerCase())
                );
              })
              .map((member) => {
                return (
                  <tr key={member.id} style={{ wordSpacing: 1, fontSize: 12 }}>
                    <td style={{ fontSize: 11 }}>
                      {member.registration_number}
                    </td>
                    <td style={{ fontSize: 11 }}>
                      {member.first_name.toUpperCase()}{" "}
                    </td>
                    <td style={{ fontSize: 11 }}>
                      {member.last_name.toUpperCase()}{" "}
                      {/* {member.middle_name.toUpperCase()} */}
                    </td>
                    <td style={{ fontSize: 11 }}>
                      {member.middle_name.toUpperCase()}
                    </td>
                    <td>
                      <span style={{ color: "rgb(5, 117, 33)" }}>MOBILE: </span>
                      {member.mobile_number}
                      <br />
                      <span style={{ color: "rgb(5, 117, 33)" }}>
                        ADDRESS:{" "}
                      </span>
                      {member.postal_address}-{member.postal_code}
                    </td>
                    <td>
                      <span style={{ color: "rgb(5, 117, 33)" }}>
                        APPLICATION DATE:{" "}
                      </span>{" "}
                      {member.application_date} <br />
                      <span style={{ color: "rgb(5, 117, 33)" }}>
                        lOAN TYPE:{" "}
                      </span>
                      {member.name} <br />
                      <span style={{ color: "rgb(5, 117, 33)" }}>REASON: </span>
                      {member.description}
                    </td>
                    <td>{Intl.NumberFormat().format(member.loan_amount)}</td>
                    <td>
                      {Intl.NumberFormat().format(member.interest_amount)}
                    </td>
                    <td>
                      {Intl.NumberFormat().format(
                        member.loan_amount + member.interest_amount
                      )}{" "}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexWrap: "nowrap",

                          gap: "2px",
                        }}
                      >
                        <div>
                          {statusType == "ACTIVE" ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setCurrentLoanId(member.loan_id);
                                setOpenDisbursementDialog(true);
                              }}
                              size="small"
                              startIcon={<PaymentOutlined />}
                              disabled={loading}
                            >
                              Make Payment
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setOpenModal(true);
                                setCurrentMemberId(member.memberId);
                                setCurrentLoanId(member.loan_id);
                                setFeedback(null);
                                setError(null);
                                setLoading(false);
                              }}
                              size="small"
                              startIcon={
                                <DeleteIcon
                                  style={{
                                    color: "#ff0000",
                                    cursor: "pointer",
                                  }}
                                />
                              }
                              disabled={loading}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                        <div>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setCurrentMemberId(member.memberId);
                              setCurrrentMemberName(
                                `${member.first_name} ${member.last_name} ${member.middle_name}`
                              );
                              setCurrentRegisrationNo(
                                member.registration_number
                              );
                              setCurrentLoanId(member.loan_id);
                              setOpenViewSchedule(true);
                            }}
                            size="small"
                            startIcon={
                              <Preview
                                style={{
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              />
                            }
                            disabled={loading}
                          >
                            View
                          </Button>
                        </div>
                        {statusType == "PENDING" && (
                          <div>
                            <Button
                              onClick={() => {
                                approveLoan(member.loan_id);
                              }}
                              variant="outlined"
                              startIcon={<Verified />}
                              size="small"
                              disabled={loading}
                            >
                              Approve
                            </Button>
                          </div>
                        )}
                        {statusType == "APPROVED" && (
                          <div>
                            <Button
                              onClick={() => {
                                // disburseLoan(member.loan_id);
                                setCurrentLoanId(member.loan_id);
                                setOpenDisbursementDialog(true);
                              }}
                              variant="outlined"
                              startIcon={<AccountBalance />}
                              size="small"
                              disabled={loading}
                            >
                              Disburse
                            </Button>
                          </div>
                        )}
                      </div>

                      {/* <Button variant="outlined" startIcon={<DeleteIcon />}>
                      Delete
                    </Button>
                    <Button variant="contained" endIcon={<SendIcon />}>
                      Send
                    </Button> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <PaginationComponent
          postsPerPage={postsPerPage}
          totalPosts={members.length}
          currentPage={currentPage}
          paginate={paginate}
        />
        <div>
          {" "}
          <select
            onChange={(e) => {
              setPostsPerPage(e.target.value);
            }}
          >
            <option>{postsPerPage}</option>
            <option>10</option>
            <option>20</option>
            <option>40</option>
            <option>60</option>
            <option>100</option>
            <option>200</option>
            <option>500</option>
            <option>1000</option>
          </select>
        </div>
        {openViewSchedule && (
          <ViewLoanSchedule
            openViewSchedule={openViewSchedule}
            setOpenViewSchedule={setOpenViewSchedule}
            loanId={currentLoanId}
            memberId={currentMemberId}
            memberName={currentMemberName}
            registrationNo={currentRegistrationNo}
          />
        )}
      </div>
    </>
  );
};

export default LoansDisplayTable;
