import React, { useEffect, useState, lazy } from "react";

import "./App.css";
import logo from "./images/logo.png";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Sidenav from "./Components/Sidenav";
import { urlPath } from "./lib/urlPath";

import LoansSummary from "./Pages/Loans/LoansSummary";
import styles from "./Components/sidenav.module.css";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { loggin, logout } from "./actions";
//import clients pages
const ClientRegistration = lazy(() =>
  import("./Pages/Clients/ClientRegistration")
);
const NextOfKinRegistration = lazy(() =>
  import("./Pages/Clients/NextOfKinRegistration")
);
const EditBioData = lazy(() => import("./Pages/Clients/EditBioData"));
const EditNextOfKin = lazy(() => import("./Pages/Clients/EditNextOfKin"));
const ViewClients = lazy(() => import("./Pages/Clients/ViewClients"));
const ViewClientFile = lazy(() => import("./Pages/Clients/ViewClientFile"));
const ViewNextOfKin = lazy(() => import("./Pages/Clients/ViewNextOfKin"));
const AddSavings = lazy(() => import("./Pages/Clients/AddSavings"));
const MemberContributions = lazy(() =>
  import("./Pages/Clients/MemberContributions")
);

//import loans pages
const AddCollateral = lazy(() => import("./Pages/Loans/AddCollateral"));
const ApplyLoan = lazy(() => import("./Pages/Loans/ApplyLoan"));
const ApproveLoans = lazy(() => import("./Pages/Loans/ApproveLoans"));
const CancelApplication = lazy(() => import("./Pages/Loans/CancelApplication"));
const DisburseLoans = lazy(() => import("./Pages/Loans/DisburseLoans"));
const GuaranteeLoans = lazy(() => import("./Pages/Loans/GuaranteeLoans"));
const LoanCalculator = lazy(() => import("./Pages/Loans/LoanCalculator"));
const ProcessLoans = lazy(() => import("./Pages/Loans/ProcessLoans"));

//import Reports Pages
const AccountBalances = lazy(() => import("./Pages/Reports/AccountBalances"));
const AccountStatement = lazy(() => import("./Pages/Reports/AccountStatement"));
const ActiveLoans = lazy(() => import("./Pages/Reports/ActiveLoans"));
const AgingReport = lazy(() => import("./Pages/Reports/AgingReport"));
const DefaultedLoans = lazy(() => import("./Pages/Reports/DefaultedLoans"));
const DisbursedLoans = lazy(() => import("./Pages/Reports/DisbursedLoans"));
const DueLoans = lazy(() => import("./Pages/Reports/DueLoans"));
const GeneralStatement = lazy(() => import("./Pages/Reports/GeneralStatement"));
const LoanBalances = lazy(() => import("./Pages/Reports/LoanBalances"));
const LoanStatement = lazy(() => import("./Pages/Reports/LoanStatement"));
const PendingLoans = lazy(() => import("./Pages/Reports/PendingLoans"));
const RejectedLoans = lazy(() => import("./Pages/Reports/RejectedLoans"));
const UndisbursedLoans = lazy(() => import("./Pages/Reports/UndisbursedLoans"));
const WaivedLoans = lazy(() => import("./Pages/Reports/WaivedLoans"));
const WrittenOffLoans = lazy(() => import("./Pages/Reports/AccountBalances"));
const PaymentReports = lazy(() => import("./Pages/Reports/PaymentReports"));

//import settings pages
const AddLoanProducts = lazy(() =>
  import("./Pages/Settings/Loans/AddLoanProducts")
);
const EditProducts = lazy(() => import("./Pages/Settings/Loans/EditProducts"));
const BusinessDetails = lazy(() =>
  import("./Pages/Settings/System/BusinessDetails")
);
const SystemConfiguration = lazy(() =>
  import("./Pages/Settings/System/SystemConfigurations")
);
const UserActivities = lazy(() =>
  import("./Pages/Settings/Users/UserActivities")
);
const UserManagement = lazy(() =>
  import("./Pages/Settings/Users/UserManagement")
);

//import expenses pages
const SupplierList = lazy(() => import("./Pages/expenses/SupplierList"));
const AddSupplier = lazy(() => import("./Pages/expenses/AddSupplier"));
const BillsList = lazy(() => import("./Pages/expenses/BillsList"));
const AddBill = lazy(() => import("./Pages/expenses/AddBill"));
const PayBills = lazy(() => import("./Pages/expenses/PayBills"));
const PayBillsReport = lazy(() => import("./Pages/expenses/PayBillsReport"));

const LoginPage = lazy(() => import("./Components/login/LoginPage"));

const Home = lazy(() => import("./Pages/Home"));

const AddNewClientSavings = lazy(() =>
  import("./Pages/Clients/AddNewClientSavings")
);
const ViewClientSavings = lazy(() =>
  import("./Pages/Clients/ViewClientSavings")
);
const SavingsReport = lazy(() => import("./Pages/Reports/SavingsReport"));
const LoanReports = lazy(() => import("./Pages/Reports/LoanReports"));

const LoanPayments = lazy(() => import("./Pages/Reports/LoanPayments"));
const LoanPaymentsReport = lazy(() =>
  import("./Pages/Reports/LoanPaymentsReport")
);
const GlListing = lazy(() => import("./Pages/Finance/GlListing"));
const AddGl = lazy(() => import("./Pages/Finance/AddGl"));
const IncomeExpenditure = lazy(() =>
  import("./Pages/Finance/IncomeExpenditure")
);
const TrialBalance = lazy(() => import("./Pages/Finance/TrialBalance"));
const BalanceSheet = lazy(() => import("./Pages/Finance/BalanceSheet"));
const AuditReports = lazy(() => import("./Pages/Reports/AuditReports"));

function App() {
  const [open, setopen] = useState(true);
  const [authMessage, setAuthMessage] = useState(false);
  const url = urlPath.mainPath;

  const toggleOpen = () => {
    setopen(!open);
  };

  const loggin_status = useSelector((state) => state.loggedReducer);
  const dispatch = useDispatch();

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    Axios.get(`${url}login_get`)
      // fetch(`${url}login_get`, {
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((result) => {
      //     return result.json();
      //   })
      .then((result) => {
        // setLog_in(true);
        setAuthMessage(result.data.message);
        if (result.data.message == "error") {
          dispatch(logout());
        } else {
          dispatch(loggin());
        }
      })
      .catch((err) => {
        console.log("Message error");
      });
  }, []);
  return (
    <>
      <div
        className="App"
        style={
          loggin_status
            ? {
                height: "auto",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }
            : {
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
              }
        }
        //   style={{
        //     height: loggin_status ? "auto" : "100vh",
        //     display: "flex",
        //     justifyContent: loggin_status ? "flex-start" : "center",
        //     alignItems: loggin_status ? "flex-start" : "center",
        //   }}
      >
        {loggin_status ? (
          <>
            <Sidenav open={open} toggleOpen={toggleOpen} />
            <div
              className={
                open ? `${styles.mainOuter}` : `${styles.mainOuterFullWidth}`
              }
            >
              <Routes>
                {/* client routes */}
                <Route
                  path="/"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <Home />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/client_registration"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ClientRegistration />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/next_of_kin_registration"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <NextOfKinRegistration />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/edit_bio_data"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <EditBioData />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/edit_next_of_kin"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <EditNextOfKin />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/view_clients"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ViewClients />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/view_client_file"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ViewClientFile />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/view_next_of_kin"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ViewNextOfKin />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/add_new_member_savings"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddNewClientSavings />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/view_member_savings"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ViewClientSavings />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/add_member_savings"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddSavings />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/savings_payments"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <MemberContributions />
                    </React.Suspense>
                  }
                ></Route>

                {/* loan routes */}
                <Route
                  path="/apply_loan"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ApplyLoan />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/add_collateral"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddCollateral />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/approve_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ApproveLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                // path="/cancel_application"
                // element={<React.Suspense fallback=' Loading... '><CancelApplication /></React.Suspense>}add_member_savings
                ></Route>
                <Route
                  path="/disburse_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <DisburseLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/guarantee_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <GuaranteeLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/loan_calculator"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanCalculator />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/process_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ProcessLoans />
                    </React.Suspense>
                  }
                ></Route>
                {/* reports routes */}
                <Route
                  path="/payment_reports"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <PaymentReports />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/loan_payments"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanPaymentsReport />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/savings_report"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <SavingsReport />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/account_statement"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AccountStatement />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/active_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <ActiveLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/aging_report"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AgingReport />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/defaulted_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <DefaultedLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/disbursed_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <DisbursedLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/due_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <DueLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/general_statement"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <GeneralStatement />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/loan_balances"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanBalances />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/loans_report"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanReports />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/loan_statement"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanStatement />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/pending_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <PendingLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/rejected_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <RejectedLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/undisbursed_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <UndisbursedLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/waived_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <WaivedLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/written_off_loans"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <WrittenOffLoans />
                    </React.Suspense>
                  }
                ></Route>
                {/* expenses routes */}
                <Route
                  path="/supplier_list"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <SupplierList />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/add_supplier"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddSupplier />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/bills_list"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <BillsList />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/add_bill"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddBill />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/pay_bills"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <PayBills />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="pay_bills_report"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <PayBillsReport />
                    </React.Suspense>
                  }
                ></Route>
                {/* finance routes */}
                <Route
                  path="/gl_account"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <GlListing />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/gl_account_add"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddGl />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/income_expenditure"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <IncomeExpenditure />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/trial_balance"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <TrialBalance />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/balance_sheet"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <BalanceSheet />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="pay_bills_report"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <PayBillsReport />
                    </React.Suspense>
                  }
                ></Route>
                {/* settings routes */}
                <Route
                  path="/add_loan_products"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <AddLoanProducts />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/edit_products"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanBalances />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/audit_report"
                  element={
                    <React.Suspense fallback="Loading ...">
                      <AuditReports />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/business_details"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <LoanStatement />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/system_configurations"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <PendingLoans />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/user_activities"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <UserActivities />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/user_management"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <UserManagement />
                    </React.Suspense>
                  }
                ></Route>
                <Route
                  path="/teau_sacco"
                  element={
                    <React.Suspense fallback=" Loading... ">
                      <Home />
                    </React.Suspense>
                  }
                ></Route>
              </Routes>
            </div>
          </>
        ) : (
          <div>
            <LoginPage />
          </div>
        )}

        {/* {<p>Auth Mesasge: {authMessage}</p>} */}
      </div>
    </>
  );
}

export default App;
