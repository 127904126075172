import React, { useState, useRef, useEffect } from "react";

import styles from "./sidenav.module.css";
import logo from "../images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { loggin, logout } from "../actions";
import { urlPath } from "../lib/urlPath";

import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  ArrowRight,
  ArrowForward,
  ArrowBack,
  Logout,
} from "@mui/icons-material";
import { navData } from "../lib/navData";
import { NavLink } from "react-router-dom";
import SideNavDropdown from "./SideNavDropdown";
import Axios from "axios";

const Sidenav = ({ open, toggleOpen }) => {
  const url = urlPath.mainPath;
  const dropdownRef = useRef(`dropdown${20}`);
  const logoutRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(null);
  const dispatch = useDispatch();
  Axios.defaults.withCredentials = true;

  // useEffect(() => {
  //   function logoutFunction() {
  //     fetch("http://localhost:2000/logout", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => dispatch(loggin))
  //       .catch(err=>console.log(err));
  //   }
  //   logoutRef.current.addEventListener("click", logoutFunction);
  //   return () => {
  //     logoutRef.current.removeEventListener("click", logoutFunction);
  //   };
  // }, [logoutRef]);

  const handleLogout = () => {
    try {
      Axios.get(`${url}logout`).then((response) => {
        dispatch(logout());
      });
    } catch (error) {
      alert(error.toString());
    }
  };

  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      <div>
        <header>
          <div className={`${styles.imageText}`}>
            <span className={styles.image}>
              <img src={logo} alt="Logo" />
            </span>

            <div className={`${styles.text} ${styles.headerText}`}>
              <span
                className={open ? `${styles.name}` : `${styles.nameClosed}`}
              >
                TEAU SACCO{" "}
              </span>
              <br></br>
              <span
                className={
                  open ? `${styles.profession}` : `${styles.professionClosed}`
                }
              >
                Management System
              </span>
            </div>
          </div>
          {/* <i className="toggle">
            <BiChevronRight></BiChevronRight>
          </i> */}
          <button className={styles.menuBtn} onClick={toggleOpen}>
            {open ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
          </button>
        </header>

        {navData.map((item, index) => {
          return (
            <li
              style={{ display: "flex", flexDirection: "column" }}
              key={item.id}
            >
              <NavLink
                // onClick={() => {
                //   openDropdown(item.id);
                // }}

                onClick={() => {
                  setCurrentTab(item.id);
                  dropdownRef.current.id = `dropdown${item.id}`;
                  //   dropdownRef.current.style.display = "block";
                  //alert(dropdownRef.current.id);
                }}
                className={`${styles.sideitem}`}
                to={item.link}
              >
                {item.icon}
                <span
                  className={open ? styles.linkText : styles.linkTextClosed}
                >
                  {item.text}
                </span>
              </NavLink>

              {item.hasOwnProperty("pages") ? (
                <SideNavDropdown
                  open={open}
                  className={`${styles.sideitem}`}
                  pages={item.pages}
                  dropdownRef={dropdownRef}
                  id={item.id}
                  currentTab={currentTab}
                />
              ) : null}
            </li>
          );
        })}
      </div>
      <div
        className={styles.bottomContent}
        ref={logoutRef}
        id="logout_div"
        onClick={handleLogout}
      >
        <NavLink className={styles.sideitem}>
          <Logout className={styles.icon} />
          <span className={open ? styles.linkText : styles.linkTextClosed}>
            Logout
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidenav;
